import './InfoWithoutDriver.css'

const InfoWithoutDriver = () => (
  <div class="services__pg">
    <div class="services__page-img services__page-img6"></div>
  <p class="services__description fz-16">
    Машина давно перестала быть роскошью, она стала обычным
    средством передвижения. Если вы приезжаете в другой город или же
    обычно не водите, но в определенный день вам необходим личный
    транспорт, то компания DreamCars – выход из этой ситуации.
  </p>

  <h3 class="services__description-title">
    Когда вам нужен прокат авто без водителя
  </h3>
  <p class="services__description-text">
    Аренда машины без водителя – выгодный способ передвижения, не
    требующих постоянных затрат. Эта услуга подходит для:
  </p>
  <ul class="services__description-list">
    <li>
      Водителей, чей в автомобиль на данный момент находится в
      ремонте, но они не хотят или не могут передвигаться на
      общественном транспорте;
    </li>
    <li>
      Лиц, нуждающихся в статусном автомобиле для определенного
      важного события;
    </li>
    <li>
      Автолюбителей, которые хотят проверить на дороге определенную
      модель авто, провести личный тест-драйв.
    </li>
    <li>
      Большой компании, которая собирается в совместную поездку, а
      машина одна;
    </li>
    <li>
      Туристов, ненадолго приехавших в город и желающих с комфортом
      передвигаться, не думая о такси или общественном транспорте;
    </li>
  </ul>

  <h3 class="services__description-title">
    Аренда авто без водителя в UNIVERSALTRANSTRADE
  </h3>
  <p class="services__description-text">Мы предлагаем:</p>

  <ul class="services__description-list">
    <li>
      В нашем автопарке большое количество автомобилей на любой
      вкус, от классических моделей до роскошных люксовых.
    </li>
    <li>
      Профессиональные консультации – менеджеры компании помогут
      подобрать машину в зависимости от вашего бюджета и цели
      аренды;
    </li>
    <li>
      Постоянное техническое обслуживание автомобилей – транспортные
      средства проходят не только плановое ТО, их состояние
      проверяется после каждого клиента.
    </li>
    <li>
      Прозрачные тарифы. Никаких скрытых платежей, вы платите только
      ту сумму, которая указана в договоре аренды.
    </li>
  </ul>

  <h3 class="services__description-title">
    Как взять на прокат автомобиль в Москве без водителя
  </h3>
  <p class="services__description-text">
    С собой вам необходимо взять:
  </p>

  <p class="services__description-text">
    – Паспорт, подтверждающий ваш возраст;
  </p>
  <p class="services__description-text">
    – Водительское удостоверение.
  </p>
  <p class="services__description-text">
    Для аренды машины без водителя вам должно быть более 23 лет, вы
    должны иметь водительское удостоверение соответствующей
    категории и стаж вождения более 2 лет.
  </p>
</div>
);

export default InfoWithoutDriver;