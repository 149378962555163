import "./AboutItems.css";

const AboutItems = () => (
  <>
    <div class="about__desc">
      <h3 class="about__desc-title">Кто Мы ?</h3>
      <p class="about__desc-text">
        Компания «UniversalTransTrade» осуществляет свою деятельность в сфере
        пассажирских перевозок, собственным автотранспортом, на территории
        Российской Федерации. Также мы предлагаем легковые автомобили, и другие
        виды автотранспорта, в прокат без водителя. Наша компания предоставляет
        качественный сервис уже более 15 лет, и отличается безупречной
        репутацией. В числе наших преимуществ можно выделить следующее:
      </p>
      <ul class="about__desc-list">
        <li class="about__desc-list-item">
          Предоставление автомобилей в прокат с водителем и без водителя;
        </li>
        <li class="about__desc-list-item">
          Большой выбор ухоженных и технически исправных автомобилей любых
          классов и категорий;
        </li>
        <li class="about__desc-list-item">Богатый опыт работы с клиентами;</li>
        <li class="about__desc-list-item">
          Высококвалифицированный персонал, с опытом работы на автопрокатном
          рынке РФ;
        </li>
        <li class="about__desc-list-item">
          Пунктуальные и вежливые водители, всегда имеющие презентабельный
          внешний вид;
        </li>
        <li class="about__desc-list-item">
          Круглосуточная информационная и техническая поддержка;
        </li>
        <li class="about__desc-list-item">
          Быстрое оформление документов – без лишних проволочек;
        </li>
        <li class="about__desc-list-item">
          Доступная стоимость, соответствующая классу автомобилей;
        </li>
        <li class="about__desc-list-item">
          Различные варианты сроков аренды – от одних суток, и до нескольких
          лет.
        </li>
      </ul>
    </div>

    <div class="about__desc">
      <h3 class="about__desc-title">
        Наши ценности значимы для нас, а задачи результативны
      </h3>
      <p class="about__desc-text">
        Мы стремимся быть полезными обществу. Предоставление только качественных
        услуг является основной задачей компании UniversalTransTrade – как в
        краткосрочной, так и в долгосрочной перспективе. Добиваемся мы этого за
        счет сплоченной работы всего нашего коллектива. Руководство компании
        выполняет обязательства в полном объеме не только перед клиентами, но и
        перед сотрудниками, а также перед партнерами компании.
      </p>
    </div>

    <div class="about__desc">
      <h3 class="about__desc-title">Радушное отношение к каждому клиенту</h3>
      <p class="about__desc-text">
        Мы не рассматриваем наших клиентов (только) в качестве источника
        получения дохода. Нашими клиентами являются люди из разных социальных
        прослоек, с разным достатком, и разными возможностями. Но мы используем
        индивидуальный подход к каждому нашему клиенту, стремясь удовлетворить
        его потребности в полной мере, и прикладываем все усилия, чтобы наши
        действия соответствовали высокому уровню обслуживания.
      </p>
    </div>

    <div class="about__desc">
      <h3 class="about__desc-title">
        Наши сотрудники объединены общими целями
      </h3>
      <p class="about__desc-text">
        Компетентность и профессионализм – основные качества наших менеджеров.
        Вам всегда посоветуют марку и модель автомобиля, отвечающего Вашим
        требованиям, а также проконсультируют по интересующим Вас вопросам. Наши
        водители – вежливые и адекватные люди, готовые к решению любых вопросов
        на взаимовыгодных условиях, в нестандартных ситуациях, не
        предусмотренных договором.
      </p>
    </div>

    <div class="about__desc">
      <h3 class="about__desc-title">Наши партнеры могут на нас положиться</h3>
      <p class="about__desc-text">
        С нашими партнерами у нас доверительные и профессиональные отношения.
        «UniversalTransTrade» на протяжении многих лет является надежным
        партнером многих ведущих компаний России. Мы всегда идем навстречу,
        выслушиваем пожелания, и исполняем их.
      </p>
      <p class="about__desc-text">
        Новым партнерам, которые только начинают с нами сотрудничать, и
        постоянным, мы гарантируем полную конфиденциальность. Как руководство
        компании, так и все сотрудники, включая водителей, соблюдают соглашение
        о неразглашении условий сотрудничества.
      </p>
    </div>

    <div class="about__desc">
      <h3 class="about__desc-title">С нами работают</h3>
      <div class="about__partners">
        <div class="about__partners-img about__partners-img1 "></div>
        <div class="about__partners-img about__partners-img2 "></div>
        <div class="about__partners-img about__partners-img3 "></div>
        <div class="about__partners-img about__partners-img4 "></div>
        <div class="about__partners-img about__partners-img5 "></div>
        <div class="about__partners-img about__partners-img6 "></div>
        <div class="about__partners-img about__partners-img7 "></div>
        <div class="about__partners-img about__partners-img8 "></div>
      </div>
    </div>
  </>
);

export default AboutItems;
