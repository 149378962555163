import './InfoTransfer.css'

const InfoTransfer = () => (
  <div class="services__pg">
    <div class="services__page-img services__page-img3"></div>
  <p class="services__description fz-16">
    Компания UNIVERSALTRANSTRADE предлагает своим клиентам выгодные
    и эффективные схемы аренды автомобилей, ориентируясь на их
    пожелания и запросы. Компания выполнит трансфер в любой аэропорт
    столицы или из аэропорта в отель.
  </p>

  <p class="services__description fz-16">
    Транспортные услуги для выполнения трансфера в аэропорт и в
    обратном направлении обеспечивают высококвалифицированные
    водители. Более того, Наша компания предоставляет Вам
    прекрасный выбор автомобилей: от эконом- до представительского
    класса. Для больших групп туристов при необходимости мы
    предложим взять в аренду микроавтобусы и автобусы.
  </p>

    <p class="services__description-text"> – Услуга предоставляется круглосуточно;</p>
    <p class="services__description-text"> – Автомобиль будет подан вовремя;</p>
    <p class="services__description-text"> – Мы следим за тем, как выглядят наши водители;</p>
    <p class="services__description-text"> – Наши водители погрузят Ваш багаж в автомобиль;</p>
    <p class="services__description-text"> – Встретим с персональной табличкой;</p>

  <p class="services__description fz-16">
    Будьте уверены, что и при раннем бронировании, и при предзаказе
    трансфера за сутки до поездки мы подадим автомобиль вовремя и
    доставим без опозданий. Профессиональный водитель будет ожидать
    вас или ваших гостей по назначенному адресу, возле зоны прилета
    в аэропорту или на перроне вокзала с табличкой, проведет до
    машины и поможет с багажом.
  </p>
  <p class="services__description-text">
    Наш персонал - это только квалифицированные работники, которые
    имеют опыт работы в пассажирских перевозках, подтвердившие свою
    квалификацию на специальных курсах. Перед выездом на линию они
    обязательно проходят предрейсовый осмотр.
  </p>
</div>
);

export default InfoTransfer;